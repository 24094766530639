import env from '../env';
import { request } from './api';
const { API_URL2 } = env;

const URL = {
  addContact: `${API_URL2}/users/contact`,
  removeContact: `${API_URL2}/users/contact`,
  importContact: `${API_URL2}/users/contact/import`,
  confirmInvites: `${API_URL2}/users/contact/accept`,
  getUsers: `${API_URL2}/users`,
  editUsers: `${API_URL2}/users`,
  resetPassword: `${API_URL2}/users/password`,
  getBankList: `${API_URL2}/banks`,
  verifyBank: `${API_URL2}/banks/verify-details`,
  getAdminDashboard: `${API_URL2}/admin/dashboard`,
  getTransactions: `${API_URL2}/admin/transactions`,
  visitorMessage: `${API_URL2}/users/visitors_message`,
  suspendUser: `${API_URL2}/admin/users`,
  getMessagesWithUser: (userId) => `${API_URL2}/users/${userId}/messages`,
  scheduleMeeting: `${API_URL2}/users/schedule-meeting`,
  fundWallet: `${API_URL2}/users/wallet`,
  getModalities: `${API_URL2}/modalities`,
  plans: `${API_URL2}/plans`,
  toggleTwoFA: `${API_URL2}/users/toggleTwoFA`,

  acceptAdminRole: `${API_URL2}/users/admin-teams/acceptInstitution`,
};

function addContact(body) {
  return request(URL.addContact, body, 'POST');
}
function sendVisitorsMessage(body) {
  return request(URL.visitorMessage, body, 'POST');
}
function getContacts() {
  return request(URL.addContact, {}, 'GET');
}
function getAdminDashboard() {
  return request(URL.getAdminDashboard, {}, 'GET');
}

function confirmInvites(body) {
  return request(URL.confirmInvites, body, 'POST');
}
function getUsers() {
  return request(URL.getUsers, {}, 'GET');
}
function getBankList() {
  return request(URL.getBankList, {}, 'GET');
}
function verifyBank({ bankCode, accNum }) {
  return request(`${URL.verifyBank}?bankCode=${bankCode}&accountNumber=${accNum}`, {}, 'GET');
}

function changePassword({ oldPassword, password }) {
  return request(URL.resetPassword, { type: 'RESET', oldPassword, password }, 'PATCH');
}

function getTransactions() {
  return request(URL.getTransactions, {}, 'GET');
}

function importContact(data) {
  return request(URL.importContact, data, 'POST', {}, { isFormData: true });
}

function editUser(body) {
  return request(URL.editUsers, body, 'PATCH');
}

function suspendUser({ id, status }) {
  return request(`${URL.suspendUser}/${id}/suspend`, { status }, 'PATCH');
}
function removeContact(param) {
  return request(`${URL.removeContact}/${param}`, {}, 'DELETE');
}

function getMessagesWithUser(userId) {
  return request(URL.getMessagesWithUser(userId), {}, 'GET');
}

function scheduleMeeting({ users, time, description, title, date }) {
  return request(URL.scheduleMeeting, { users, time, description, title, date }, 'POST');
}

function fundWallet(param) {
  return request(URL.fundWallet, param, 'POST');
}
function getModalities() {
  return request(URL.getModalities, {}, 'GET');
}

function getPlans() {
  return request(URL.plans, {}, 'GET');
}
function subscribePlan(param) {
  return request(`${URL.plans}/${param}/institution/subscribe`, {}, 'POST');
}

function confirmAdminInvite(body) {
  return request(URL.acceptAdminRole, body, 'POST');
}

function toggleTwoFA() {
  return request(URL.toggleTwoFA, {}, 'PATCH');
}

const userService = {
  addContact,
  confirmInvites,
  getUsers,
  getContacts,
  getBankList,
  verifyBank,
  changePassword,
  getAdminDashboard,
  getTransactions,
  sendVisitorsMessage,
  importContact,
  editUser,
  suspendUser,
  removeContact,
  getMessagesWithUser,
  scheduleMeeting,
  fundWallet,
  getModalities,
  getPlans,
  subscribePlan,
  confirmAdminInvite,
  toggleTwoFA,
};

export default userService;
