import React, { useEffect, useState, lazy, Suspense, useMemo } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { store } from './redux/store';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import Smartlook from 'smartlook-client';
import { SkeletonTheme } from 'react-loading-skeleton';

import 'antd/dist/antd.less';
import './static/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import { getToken, removeUserSession } from './utility/helpers';
import { logOut } from './redux/authentication/actionCreator';

import HomePage from './container/pages/LandingPage';
import RadHome from './container/pages/LandingPage/RadHome';
import InstHome from './container/pages/LandingPage/InstHome';
import Terms from './container/pages/LandingPage/Terms';
import Privacy from './container/pages/LandingPage/Privacy';
import UnauthorizedCallUser from './container/pages/UnauthorizedCallUser';
import CallRoom from './container/pages/CallRoom';
import MeetingRoom from './container/pages/MeetingRoom';
import Entry from './container/pages/MeetingRoom/Entry';
import MeetingSession from './container/pages/MeetingRoom/MeetingSession';

const NotFound = lazy(() => import('./container/pages/404'));
const Admin = lazy(() => import('./routes/admin'));
const Auth = lazy(() => import('./routes/auth'));
const AccountSelect = lazy(() => import('./container/profile/authentication/overview/AccountSelect'));
const UserDicomViewer = lazy(() => import('./container/pages/UserDicomViewer/UserDicomViewer'));
const Uploader = lazy(() => import('./container/pages/Uploader'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state?.ChangeLayoutMode?.rtlData,
      topMenu: state?.ChangeLayoutMode?.topMenu,
      mainContent: state?.ChangeLayoutMode?.mode,
      isLoggedIn: state?.auth?.login,
    };
  });

  const token = getToken();
  const tokenChanged = useSelector((state) => state.app.token);

  const isValid = useMemo(() => !!token, [token, tokenChanged]);

  const dispatch = useDispatch();

  const handleLogout = () => {
    removeUserSession();
    dispatch(logOut(() => history('/app/auth')));
  };

  useEffect(() => {
    const idleTimeout = 3600000; // 1 hour
    let idleTimer;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(handleLogout, idleTimeout);
    };

    document.addEventListener('mousemove', resetIdleTimer);
    document.addEventListener('keydown', resetIdleTimer);

    return () => {
      document.removeEventListener('mousemove', resetIdleTimer);
      document.removeEventListener('keydown', resetIdleTimer);
      clearTimeout(idleTimer);
    };
  }, []);

  useEffect(() => {
    Smartlook.init('48778059af7c4c6a45f6cde4d1d9eeb6ea9fa450');
  }, []);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <ToastContainer style={{ fontSize: '14px', lineHeight: '17px' }} autoClose={3000} pauseOnHover={true} />
        <Router basename={process.env.PUBLIC_URL}>
          <Suspense
            fallback={
              <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
                <Spin />
              </div>
            }
          >
            <Routes>
              <Route path="/app/auth/*" element={<Auth />} />
              {isValid ? (
                <>
                  <Route path="/app/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                  <Route path="/app/selectAccount" element={<AccountSelect />} />
                </>
              ) : (
                <Route path="/*" element={<Navigate to="/app/auth" />} />
              )}
              <Route path="/" element={<HomePage />} />
              <Route path="/radiologist" element={<RadHome />} />
              <Route path="/institution" element={<InstHome />} />
              <Route path="/terms-conditions" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/meet" element={<UnauthorizedCallUser />} />
              <Route path="/meet/:meetingId" element={<CallRoom />} />
              {/* <Route path="/meet" exact element={<MeetingRoom />}>
              <Route index element={<Entry />} />
              <Route path=":meetingId" element={<MeetingSession />} />
            </Route> */}
              <Route path="/user/viewer" element={<UserDicomViewer />} />
              <Route path="/uploader/*" element={<Uploader />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <SkeletonTheme baseColor="#e5e5e5" highlightColor="#fefefe">
        <ProviderConfig />
      </SkeletonTheme>
    </Provider>
  );
}

export default App;
